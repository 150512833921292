@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "brik";
  src: url("../src/fonts/btbrik-condensed-webfont.woff ");
}

@font-face {
  font-family: "mori";
  src: url("../src/fonts/PPMori-Regular.woff ");
}
